import * as React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../../components/layout";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Seo from "../../components/seo";
import illustration from "../../images/illustration.png";

const BlogPage = ({ data }) => {
  return (
    <Layout>
      {/* header start */}
      <div className="overflow-hidden bg-yellow-400 px-6 pt-6 pb-28 sm:px-0">
        <div className="relative mx-auto max-w-lg">
          {/* nav start */}
          <span className="relative z-10 flex gap-4">
            <Link
              to="/"
              className="text-base font-semibold text-black/50 hover:text-black hover:underline">
              Sahil Khan
            </Link>
            <Link
              to="/about"
              className="text-base font-semibold text-black/50 hover:text-black hover:underline">
              About
            </Link>
            <Link to="/blog" className="text-base font-semibold text-black">
              Blog
            </Link>
          </span>
          {/* nav end */}
          <img
            src={illustration}
            alt="illustration of Sahil Khan"
            className="absolute top-0 -right-36 w-96 sm:-top-6 sm:-right-52"
          />
        </div>
      </div>
      {/* header end */}

      {/* body start */}
      <div className="mx-auto max-w-lg px-6 pt-12 sm:px-0">
        <main className="prose prose-invert prose-a:text-gray-400 hover:prose-a:text-gray-200 prose-hr:border-gray-800 md:grid md:grid-cols-2 md:gap-6">
          {data.allMdx.nodes.map((node) => (
            <article key={node.id} className="mb-10">
              <a href={`blog/${node.slug}`}>
                <GatsbyImage
                  image={getImage(node.frontmatter.heroImage)}
                  alt={node.frontmatter.heroImageAlt}
                  className="mb-2 aspect-[3/2] rounded-2xl border border-white/10 [&>*>*]:rounded-2xl"
                />
              </a>
              <h1 className="mb-2 text-xl font-bold text-gray-300">
                <a
                  href={`blog/${node.slug}`}
                  className="font-bold !text-white no-underline">
                  {node.frontmatter.title}
                </a>
              </h1>
              <p className="mb-4 text-sm">{node.frontmatter.datePublished}</p>
            </article>
          ))}
        </main>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMdx(sort: { fields: frontmatter___datePublished, order: DESC }) {
      nodes {
        frontmatter {
          datePublished(formatString: "MMMM D, YYYY")
          title
          heroImage {
            childImageSharp {
              gatsbyImageData
            }
          }
          heroImageAlt
        }
        id
        slug
      }
    }
  }
`;

export default BlogPage;

export const Head = () => <Seo title="Blog" />;
